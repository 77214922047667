export default {
  ALL_ITEMS: 'all',
  DEFAULT_COMMENTS_COUNT: 3,
  DEBOUNCE_TIME: 500,
  UNPROCESSABLE_ENTITY: 422,
  UNAUTHORIZED: 401,
  NOT_FOUND: 404,
  SERVER_ERROR: 500,
  STATUS_SUCCESS: 200,
  RADIX_DECIMAL: 10,
  PER_PAGE_DEFAULT: 30,
  PER_PAGE_MESSAGE: 20,
  PER_ALL_PAGE: 999,
  MAX_SHIFT_COUNT: 4,
  MAX_ITEM_SHOW: 6,
  MAX_REMOVAL_REASONS: 30,
  MAX_SHIFT_ROLE_COUNT: 200,
  MAX_COUNT_SHOW_MONTH_GIG: 15,
  MAX_COUNT_SHIFT_TEMPLATES: 150,
  MAX_TITLE_LENGTH: 75,
  MAX_NAME_LENGTH: 150,
  MAX_SETTINGS_TITLE: 250,
  MAX_DESCRIPTION_LENGTH: 255,
  MAX_SHIFT_DESCRIPTION: 500,
  MAX_LENGTH_500: 500,
  MAX_LENGTH_1000: 1000,
  MAX_AMOUNT_CUSTOM_ATTR: 3,
  COUNT_DAYS_FOR_RECURRING: 14,
  MAX_SIZE_UPLOAD_IMAGE: 10000000,
  MAX_SIZE_UPLOAD_LOGO: 3000000,
  MAX_UPLOAD_COUNT: 5,
  MAX_UPLOAD_SIZE: 5000000,
  ESCAPE_KEY: 27,
  ENTER_KEY: 13,
  TAB_KEY: 9,
  GIG_DELAY: 60000,
  GIG_DELAY_MAX: 120000,
  DELAY_1MINUTE: 60000,
  DELAY_5MINUTES: 300000,
  DELAY_10MINUTES: 600000,
  DELAY_1HOUR: 3600000,
  DELAY_3HOURS: 10800000,
  DELAY_6HOURS: 21600000,
  ACTIVE_STATUS: 'active',
  ACCEPTED_STATUS: 'accepted',
  APPLIED_STATUS: 'applied',
  APPROVED_STATUS: 'approved',
  ARCHIVED_STATUS: 'archived',
  AVAILABLE_STATUS: 'available',
  BLOCKED_STATUS: 'blocked',
  BUSY_STATUS: 'busy',
  BUSY_FOREIGN_STATUS: 'busy_foreign',
  CLOSED_STATUS: 'closed',
  CONNECTING_STATUS: 'connecting',
  CONNECTED_STATUS: 'connected',
  CORRECT_STATUS: 'correct',
  DAY_OFF_STATUS: 'day_off',
  TIME_OFF: 'time_off',
  DEACTIVATED_STATUS: 'deactivated',
  DECLINED_STATUS: 'declined',
  DRAFT_STATUS: 'draft',
  INCORRECT_STATUS: 'incorrect',
  INVITED_STATUS: 'invited',
  SENT_STATUS: 'sent',
  SIGNED_STATUS: 'signed',
  GENERATED_STATUS: 'generated',
  NOT_LOGGED_STATUS: 'not_logged',
  JUST_CREATED_STATUS: 'just_created',
  PENDING_STATUS: 'pending',
  PENDING_WORKERS_STATUS: 'pending_workers',
  PARTIALLY_PENDING_STATUS: 'partially_pending',
  PENDING_APPROVAL_STATUS: 'pending_approval',
  EXPIRED_STATUS: 'expired',
  REJECTED_STATUS: 'rejected',
  SOURCED_STATUS: 'sourced',
  UPCOMING_STATUS: 'upcoming',
  JOINED_STATUS: 'joined',
  TRIAL_STATUS: 'trial',
  PAID_STATUS: 'paid',
  ONLINE_STATUS: 'online',
  OFFLINE_STATUS: 'offline',
  INACTIVE_STATUS: 'inactive',
  AUTO_APPROVE_TYPE: 'auto_approve',
  ASSIGN_TYPE: 'assign',
  BASIC_TYPE: 'basic',
  MANUAL_APPROVE_TYPE: 'manual_approve',
  GIG_TYPE: 'gig',
  DIRECT_TYPE: 'direct',
  GROUP_TYPE: 'group',
  EXTENDED_TYPE: 'extended',
  EXTERNAL_TYPE: 'external',
  INTERNAL_TYPE: 'internal',
  ARCHIVE_TYPE: 'archive',
  RESTORE_TYPE: 'restore',
  DEACTIVATE_TYPE: 'deactivate',
  SELECTIVE_TYPE: 'selective',
  END_TYPE_ON: 'on',
  END_TYPE_AFTER: 'after',
  FREEMIUM_SUBS: 'freemium',
  INVITE_STR: 'invite',
  PUBLISH_STR: 'publish',
  CLIENTS_STR: 'clients',
  MANAGERS_STR: 'managers',
  WORKER_STR: 'worker',
  WORKER_MONTH_STR: 'worker-month',
  WORKLOG_STR: 'worklog',
  ORGANIZATION_STR: 'organization',
  REASONS_STR: 'reasons',
  HOURS_STR: 'hours',
  ENTIRE_STR: 'entire',
  EXPENSES_STR: 'expenses',
  ORT_STR: 'ort',
  SHIFT_TYPES_STR: 'shift-types',
  REPORT_STR: 'report',
  DAY_STR: 'day',
  WEEK_STR: 'week',
  BIWEEKLY_STR: 'biweekly',
  WEEKDAY_STR: 'weekday',
  THREE_WEEKS_STR: '3weeks',
  FOUR_WEEKS_STR: '4weeks',
  FIVE_WEEKS_STR: '5weeks',
  MONTH_STR: 'month',
  YEAR_STR: 'year',
  AGENDA_STR: 'agenda',
  SINGLE_STR: 'single',
  FOLLOWING_STR: 'following',
  ALL_STR: 'all',
  DELIMITERS: [',', ';', '/', ':', '\\?'],
  CALENDAR_SHORT_VIEW: ['day', 'week', 'month'],
  CALENDAR_ALL_VIEW: ['day', 'week', 'agenda', 'worker', 'worker-month'],
  WEEKDAY_ARR: ['monday', 'tuesday', 'wednesday', 'thursday', 'friday'],
  WEEK: {
    monday: 1,
    tuesday: 2,
    wednesday: 3,
    thursday: 4,
    friday: 5,
    saturday: 6,
    sunday: 7,
  },
  LANGUAGES_LIST: ['en', 'nl'],
  LANGUAGES_ROUTES_LIST: ['/en', '/nl'],
  PRIMARY_COLOR: '#3F32B1',
  SECONDARY_COLOR: '#007D8B',
  url: {
    clients: 'clients',
    managers: 'managers'
  },
  utils: {
    multipleOfTwo: 2,
  },
  SUBSCRIPTION_PLANS: [
    'starter2021',
    'starter2022', 'pro2022', 'team2022', 'enterprise2022',
    'starter2024', 'pro2024', 'team2024', 'essential2024',
  ],
  SUBSCRIPTION_PLANS_BUNDLE: ['starter2020', 'pro2020', 'team2020'],
  XLSX_TYPE: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  SOCIAL_NETWORKS: [
    'website',
    'facebook',
    'instagram',
    'linkedin',
  ],
  EXTERNAL_BLOCKED: '666b505a5b94c0efd3535aae03ac9eb5b00155c543b64058865c008510346e37',
  CLIENT_SHIFT_VALIDATION: 'b3112cb623ab806a27fd57f141633c7ac668932ef7bdab642eba0433ecef9fec',
  CLIENT_REQUIRED_ATTR_ID: 2080,
  CLIENT_SHIFT_VALIDATION_RATE: 'a487916ea02b6997b9d1a66c0b445a94416d4d153b0029c12bdd0cb8fa0680ab',
  CLIENT_REQUIRED_ATTR_RATE_ID: 2928,
  REDCROSS_CLIENT_1: 'c23d7cecbb85de57c47c7302464db69aed0777b19f8f99985d1932e5b11b6917',
  REDCROSS_CLIENT_2: '364137948f7973b758dcee10ef0f88c8df8e50d2fcca307d3ae88d0580a315b5',
  REDCROSS_CLIENT_2_WORKER_ATTR: [2965, 2970, 2966],
  REDCROSS_CLIENT_3: 'd1535138b1f59c159eba5949a7a57d155fd4c5d667e03bfcfc2e60a8afdaa816',
  REDCROSS_CLIENTS: [
    'c23d7cecbb85de57c47c7302464db69aed0777b19f8f99985d1932e5b11b6917',
    '364137948f7973b758dcee10ef0f88c8df8e50d2fcca307d3ae88d0580a315b5',
  ],
  REDCROSS_CLIENT_BLOCKED: 'd1535138b1f59c159eba5949a7a57d155fd4c5d667e03bfcfc2e60a8afdaa816',
  AUTO_APPROVE_BLOCKED: 'a24fcbbfaa1c391809bfc399314d05d79c01c05ec2c1d5f2eaa2ee3415c57a03',
  INTERNAL_BLOCKED_EXCEPT: '4acc9466b07dba170a85cd39155fe2fddba8d9966aa5711c56ce01b0a618f1ae',
  INTERNAL_BLOCKED_FLETCHER: 'b4218e05bde87b849c2ef86254d0080b8f8435d4fdf77f0aae94b0f8d78dff20',
  INTERNAL_BLOCKED_SWZZORG: [
    'd7971b366301d344c1b9885b39769e67bd6b84af8117d2cbb7838958635c411a',
    '6fc71505a2cb7670b0b9dd6eb3f68c512dda8b39f0384c495ec8a637af89174e',
    '518b137ed7594bcb60338582a0930a6e5ab140e7133bf2503378dff5b4290d7a',
    '69fb9a67c1f3ce1dbd167c6c6c235f24716228df572c6299f93d0f1c0e4f272f',
    'fe1d5f9b50e3595ddaf63042af7b8b327ebb56a165c793e21332ec73b72e903e',
    'ddad74ccc917447f09beabd6cbe36bf8d7276fc3f66904236e306105e1889207',
    '930cc18877ad6161c63e21fd21a6f2b40097f92d4db9a3df3e19fed21dc504c6',
    '2eb446e88092c8f4f5689fd04b994c8ddd8b224be86fb883fc2faee8a4bd0f5b',
    'd9ca715fce8490ac0c770d4f43e8e1ab578dd79a2512b3d4729c989bf5e1bec7',
    'fee7425689cdb2bbe70ad01d4a26de1e481c5491843637d7bee18a1b8ac23fdd',
    '70de26c704d901b125ab3eeaea597851b2f290058fc92ee62ac2a7a1335084c5',
    '40950efe9ac436918efa607c6fdd7458e35efd180235d909670b86becd44e68c',
    'f0bc3739a6f556990b45bba92f0752075ec1d5fb13fb9f3752f1713509766fc2',
    '8ee415967f9a686a1b99548a0fbcd13ed3d4a54a81ef5669c066c8f55acf5cb0',
    'c122e9ed107619f76a763626e735007e6006c45bb84b6861f36d8a97d01ffc32',
    'b0033d0f0de810db668d6ba1a36ccb18580db1dc07c33d689ba12620e15d6f1f',
    'b9ae1368c07f96222442779503d8500a92fcf8f9f6217a69360f8fea9acfdf3b',
    '8a9c3821598a0d46c11609e10b7135e2215c93c67c90b60b5805767c99db20af',
    '8fae68c0f71a725e93f170e4a893e28835d228ed9884960c4148e91f55be8c86',
    'cba0b61ce6c4208dbd8f59dae18fbdcc4d0a6bbcddf9d8ca793ad84547d8fcdb',
    '580e696db20baa8d5a3f8bc33419bc58560d251be7d895189b78b0affede1b87',
    '10525c5d6a359055ce9b1dd4769a705197d6ef16117a21adeb8c12e736d84c67',
    '94ff9f1fa74689590e100310eb871bf4b8bf84e2f0278838d887b09a53f55a08',
    '201d3d8e279f37ea3e0d5f4b9c3350208bbc34b3b9824d10f7dc3e76a5507a87',
    '140274e6e18a02ad14e0a9277d48b109724e28bfda08ec355b03faef64e95f9c',
    '0663880d609abd27b55953e7690c5cfac8a2cac70cb15de75179f4b0ff7f9c6b',
    '564336731ac7f5551a5a5b642050ff4cbf0fa04f97d1a17a1ecdeb8c1373393a',
    'a340c6e5f329bb9fa454fc86452f70f49303ddf3eb632fdc42e31c940db2758e',
    '6e44cf7a28ae296d5ada75897a35e8e37b27f8f5709e692aed95f3f971cd3711',
    'cd666557eb82d8e4ea10376443ae9dd3b125d52a6ed6a8dd8757fa6b432f4c40',
    '51333ab06351964b2f2e643ca372177cd78e9c627719f4f08fea33e6b9e05783',
    '8c59ba0f16ede611039c44b586f744349ed4d4e68cfab6d8b9677b7ec19f36d3',
    'd782fd7b5becfe84270b9233f0cab30019da0d746130f98464bdd965a5477aa6',
    'e53b61aeb4fbab10813c6399cea5f1a5341fc34866994d3f580769937b9feb86',
    'd6b6270e0945a7a58435002fa8ed5a93abfe2920afd21b885008b8882f188065',
    '6f7a098161c633b851952da3f735b0ce30ec51e35470dc46b7f7badbe56af7a2',
    '692ebc37b1028cb363c9df5ea707a6246dd8729c361ac842d25d5847337bed69',
    'c3828e70eada1e33980546a4291e56dbbfe2ec63dc08275242cc6a8c6851d44d',
    '86a5e601cdaf27ec1dc4e6ffd4e5a9e2528622dc5fcb43725412789f9d4c6039',
    'a8a1d5cb9b7cdc93a26d663412187fa647022310e5aa5724bf94d555cd18ee97',
    '5eab10f44b15dd01fd00207a2a057ecee377fc0b72341ff093a5c5c57e1d2940',
    '692ebc37b1028cb363c9df5ea707a6246dd8729c361ac842d25d5847337bed69',
    '5d1d8960f818564d62493ed3c8a7c8eb7ed0906da065469cd5df4aa9c15fdd3e',
    '174085f588d7c8713561dba5a842ad8cd2fb4fb8e4abc1d959510502e8dd6963',
    '1b72b62d7eba4c2f80581cfddd0f25e7bedab672e3159b6013db9a8ee807935a',
    '0f4442863d1060f4db544dce0a8c4d03cc428a0c9223aa18bbbb5df4e2e6c8e4',
    'd148d9e576115c26b3334b4fac3b0de21037498f619b59b6eb95e7529281a4c0',
    'f2958e0032c9c08f2e66a4393b3d608db6e0419dcc5f6f485f4503eb2c319eb7',
    'dac1cbe1c40f3a5c5e4a194ef492aaa6038dd3f94339d79e026414b5bbdf2cdf',
  ],
  INTERNAL_MEDICAL_HUNT: [
    '0500976a6005ee56eb71d94d8086ef7fa25d88c19a8f5b854ca7e5d425167f56',
    'be82dbe24ba91cc4f05908f1d4c6d592e1287e9c16461971210135d675581976',
  ],
  EXCELLENT_FLEX_CLIENT: [
    'a1b674bfdcdede5acbed200e13208d86b8ec2916d11a415f1729c201abe6a157',
    'adf611c663f0addfe4003790561623eb1017fbf1b2faa6423925b64bfae5ba00',
  ],
  INTERNAL_BLOCKED_KEISTAD_ZORG: '83156d4bd58e60bfbfaaf391f4ecf1dd40c5d066052f58d3983bde70d847782a',
  INTERNAL_BLOCKED_KIZ: [
    '6c7a900a12e3ca0a92f7dc1670aa7a60b2b4eebd5ed40f78a313085bc2bbccea',
    'bafb9e4bc6eb0f338a30d22614941f6555773450165e50114aad87558277c6c7',
    '2788463d244d3b58233ce69f12bcdc684cdbb163ce08b7a1060aa52a2bf35d4f',
    '22bae37b70a26cbfa8f2ca088c6c921adf09f624707fb26de1f45d5d1df55b78',
    '5756efead845316c26afd96254b5d69f5d1ec5194ddfc992a88c14df72a0f209',
    'ac8246ce4aa44a71e5570643e08c4a5d94ac9bcfbfc4208fb0fcaee26977f7cb',
    'daf7e61bcbcb96390f840e2039c2030832d81d59e5a6c8946a8f23c5202b4990',
    'faa9f30935a06df48bcf4039cd2c6027130b2536b471bab4be3ae9d5c563bd74',
  ],
  LOGISTIC_FORCE_MANAGERS_EXCEPTION: [
    '84df262c492e669811d761e111707f813a219036c701983d86f7aea315a57f74',
    'c38f2dae8c4d3a861c3d2a93e012ceca382ae203b2fdb1e5522b2fe380e4e44d',
  ],
  MANAGERS_WITHOUT_TIME_TRACKING: [
    '02ec10e84c1c63ed3e208b624b0af58795cc1e490efd15a127d914565b63f131',
    '877be6359b2b4757a3effd125e2ea540a76ecb79789d80129bcd68298ec0aa35',
    '2e3c4a18ad3055f783448ec81194ca543cac821033ce5e79d57cd95790ae1123',
  ],
  EXCLUDED_ATTRIBUTES: ['Medewerker ID', 'Straat', 'Huisnummer', 'Toevoeging aan huisnummer', 'Postcode', 'Stad'],
  EXCLUDED_ATTRIBUTES_IDS: [2811, 2812, 2813, 2814, 2815],
  EDITABLE_ATTRIBUTES_LF_IDS: [17],
  EXTENSIONS_TEXT: [
    'application/pdf',
    'text/plain',
    'application/msword',
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
    'application/vnd.ms-excel',
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    'text/csv',
    'application/vnd.oasis.opendocument.text',
    'application/vnd.oasis.opendocument.spreadsheet',
  ],
  EXTENSIONS_IMG: [
    'image/jpeg',
    'image/png',
    'image/gif',
  ],
  BARTKRAMER_CLIENT: '576b1f3320bb7c5ce7edf8022ed3683fea417bede3ed34468c21a02f8a9f9182',
  FACTUREN_YZORG: '16eef00f27628e1b59374f4e966a003ccca172d952ae0b81f780f2fab81c4f5d',
  FLEXWERKERZ: {
    email: '84843c75a198a57613ba5446e43a9e58709506b3fd6a34081159e1126a2dafc4',
    gigAttribute: '5878',
    workerFilter: '4933'
  }
};
